
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  applications,
  roleMappings,
  roles,
  users,
  shops,
  roleDeleteRequests,
} from '../../resources';
import {Role} from '@/externals/Core-Role-v1';
import {ObjectId} from 'bson';
import {RoleMapping} from '@/externals/MaxCI-RoleMapping-v1';
import lodash from 'lodash';

interface ScopeRoleMapping extends RoleMapping {
  scopeName?: string;
}
@Component({
  name: 'userRoleList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private saveLoading = false;
  private applicationId = this.$route.params.applicationId;
  private total = 0;
  private roles: Array<Role> = [];
  private list: Array<ScopeRoleMapping> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private userId: ObjectId = ObjectId.createFromHexString(
    this.$route.params.userId,
  );
  private name = '';
  private dialogDel = false;
  private operateId: ObjectId | null = null;
  private operateData: ScopeRoleMapping | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private roleNameData: any = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private appNameData: any = [];
  private roleName = '';

  async created() {
    if (this.applicationId === localStorage.getItem('applicationId')) {
      this.roleName = '平台管理员';
    } else {
      this.roleName = '应用管理员';
    }
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.roles = await roles.find(stage => stage);
    this.roleNameData = lodash.zipObject(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.roles.map(v => v._id.toHexString()) as any,
      this.roles,
    );
    this.name =
      (
        await users.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.userId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true)?.spec.name ?? '';
    await this.updateList();
  }
  private getRoleName(id: ObjectId) {
    return this.roleNameData[id.toHexString()].spec.name;
  }
  //删除提示
  private delPopUps(row: ScopeRoleMapping) {
    this.operateId = row._id;
    this.operateData = row;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      this.saveLoading = true;
      if (this.roleName === '应用管理员' && this.operateData) {
        const type =
          this.getRoleName(this.operateData.spec.roleId) === '应用用户_邀请员'
            ? '应用邀请员'
            : this.getRoleName(this.operateData.spec.roleId);
        const roleDeleteRequest = await roleDeleteRequests.create(
          [
            {
              spec: {
                type: type,
                ...(type === '应用邀请员'
                  ? {}
                  : {shopId: this.operateData.spec.scopeId}),
                userId: this.userId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        );
        if (roleDeleteRequest) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      } else {
        if (this.operateId) {
          const roleMapping = await roleMappings.delete(filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.operateId as ObjectId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          );
          if (roleMapping) {
            this.dialogDel = false;
            this.operateId = null;
            this.$message.success('已删除');
            this.updateList();
          }
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }

  //获取列表
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await roleMappings.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('userId'),
              e => e.$eq(this.userId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.list = await Promise.all(
        list[0].table.map(async v => {
          const role = this.roles?.find(r => r._id.equals(v.spec.roleId));
          return {
            ...v,
            scopeName:
              role?.spec.name === '店铺管理员' || role?.spec.name === '店铺财务'
                ? (
                    await shops.find(stage =>
                      stage.$match(match =>
                        match(
                          f => f('_id'),
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          e => e.$eq(v.spec.scopeId!),
                        ),
                      ),
                    )
                  ).find(() => true)?.spec.name ?? ''
                : localStorage.getItem('roleName') === '平台管理员'
                ? (
                    await applications.find(stage =>
                      stage.$match(match =>
                        match(
                          f => f('_id'),
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          e => e.$eq(v.spec.scopeId!),
                        ),
                      ),
                    )
                  ).find(() => true)?.spec.name ?? ''
                : '',
          };
        }),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
